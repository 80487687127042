import React, { useState } from "react"
import {
  ContentContainer,
  PostCard,
  Tag,
  Category,
  BlogContent,
  Seo,
} from "../components"
import { graphql, Link } from "gatsby"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { Fade, Slide } from "react-awesome-reveal"

const AllPost = ({ data }) => {
  const allPosts = data.postsRemark.edges
  const postCount = data.postsRemark.totalCount
  const allTags = data.tagsGroup.group
  const allCategories = data.categoryGroup.group
  const defaultImage = data.defaultImage.fluid

  // PAGINATION PROPS

  const [currentPaginationPage, setCurrentPaginationPage] = useState(1)

  const maxPaginationPage = Math.ceil(data.postsRemark.totalCount / 4)
  const isFirst = currentPaginationPage === 1
  const isLast = currentPaginationPage === maxPaginationPage

  const handlePaginationChange = i => {
    if (i >= 0 && i <= postCount) {
      setCurrentPaginationPage(i)
    }
  }

  // SEARCH PROPS

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    const query = event.target.value
    const posts = allPosts || []

    const filteredData = posts.filter(post => {
      const { description, title, tags } = post.node.frontmatter
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags && tags.join("").toLowerCase().includes(query.toLowerCase()))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults
    ? filteredData
    : allPosts.slice((currentPaginationPage - 1) * 4, currentPaginationPage * 4)

  return (
    <ContentContainer>
      <Seo
        title="Blog Posts"
        // image={seoImage}
        description="Page for guides, tutorial, or just notes."
      />
      <BlogContent>
        <div className="container">
          <section className="blog-content">
            {posts.map(({ node }, index) => (
              <PostCard
                key={index}
                to={node.frontmatter.slug}
                description={node.frontmatter.description}
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                fluidImage={
                  node.frontmatter.image
                    ? node.frontmatter.image.childImageSharp.fluid
                    : defaultImage
                }
              />
            ))}

            {!hasSearchResults && (
              <div className="pagination">
                {!isFirst && (
                  <button
                    className="button"
                    onClick={e =>
                      handlePaginationChange(currentPaginationPage - 1)
                    }
                  >
                    <BsChevronLeft />
                  </button>
                )}

                {Array.from({ length: maxPaginationPage }, (_, index) => (
                  <button
                    className={`button ${
                      currentPaginationPage == index + 1 ? "active" : ""
                    }`}
                    key={index}
                    // active={currentPaginationPage == index + 1 ? true : false}
                    onClick={e => handlePaginationChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}

                {!isLast && (
                  <button
                    className="button"
                    onClick={e =>
                      handlePaginationChange(currentPaginationPage + 1)
                    }
                  >
                    <BsChevronRight />
                  </button>
                )}
              </div>
            )}
          </section>

          <section className="blog-sidebar">
            <div className="side-container">
              <form className="search-form">
                <input
                  className="search-field"
                  type="search"
                  placeholder="Search Posts"
                  aria-label="Search Posts"
                  onChange={handleInputChange}
                />
              </form>
            </div>

            {/* <Tags /> */}
            <div className="side-container">
              <h1>Tags</h1>
              {allTags.map((node, index) => (
                <Tag key={index} href={`/tags/${node.fieldValue}/`}>
                  {node.fieldValue.replace("-", " ")}
                </Tag>
              ))}
            </div>

            {/* <Categories /> */}
            <div className="side-container">
              <h1>Categories</h1>
              {allCategories.map((node, index) => (
                <Category
                  key={index}
                  href={`/categories/${node.fieldValue}/`}
                  count={node.totalCount}
                >
                  {node.fieldValue.replace("-", " ")}
                </Category>
              ))}
            </div>
          </section>
        </div>
      </BlogContent>
    </ContentContainer>
  )
}

export default AllPost

export const pageQuery = graphql`
  query {
    postsRemark: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM YYYY")
            tags
            slug
          }
        }
      }
      totalCount
    }
    defaultImage: file(relativePath: { eq: "coding_default.jpg" }) {
      childImageSharp {
        # Specify a fluid image and fragment
        # The default maxWidth is 800 pixels
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tagsGroup: allMdx(
      limit: 2000
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    categoryGroup: allMdx(
      limit: 2000
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`
